import './bootstrap';
import '../css/app.css';
import '../css/devcube.css';
import React from 'react'
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';

import {apiPlugin, storyblokInit} from "@storyblok/react";
import ContentPage from "@/Components/ContentPage.jsx";
import Hero from "@/Components/Hero.jsx";
import aa from 'search-insights';
import {ChatProvider} from "@/Components/ChatContext.jsx";
import withSuspense from "@/Util/withSuspense.jsx";

storyblokInit({
    accessToken: import.meta.env.VITE_STORYBLOK_TOKEN,
    cache: {
        clear: "manual",
        type: "memory"
    },
    use: [apiPlugin],
    components: {
        'content-page': ContentPage,
        'hero': Hero,
        'activity-block': withSuspense(React.lazy(() => import('@/Components/Home/ActivityBlock.jsx'))),
        'activity-grid': withSuspense(React.lazy(() => import('@/Components/Home/ActivityGrid.jsx'))),
        'banner': withSuspense(React.lazy(() => import('@/Components/Banner.jsx'))),
        'banner-top': withSuspense(React.lazy(() => import('@/Components/BannerTop.jsx'))),
        'category-block': withSuspense(React.lazy(() => import('@/Components/CategoryBlock.jsx'))),
        'category-grid': withSuspense(React.lazy(() => import('@/Components/CategoryGrid.jsx'))),
        'center-hero': withSuspense(React.lazy(() => import('@/Components/CenterHero.jsx'))),
        'collection-slider': withSuspense(React.lazy(() => import('@/Components/Home/CollectionSlider.jsx'))),
        'faq': withSuspense(React.lazy(() => import('@/Components/FAQ.jsx'))),
        'glasses-slider': withSuspense(React.lazy(() => import('@/Components/Home/GlassesSlider.jsx'))),
        'highlighted-brands': withSuspense(React.lazy(() => import('@/Components/Home/HighlightedBrands.jsx'))),
        'tag': withSuspense(React.lazy(() => import('@/Components/Home/Tag.jsx'))),
        'tag-list': withSuspense(React.lazy(() => import('@/Components/Home/TagList.jsx'))),
        'welcome': withSuspense(React.lazy(() => import('@/Components/Home/Welcome.jsx'))),
        'welcome-column': withSuspense(React.lazy(() => import('@/Components/Home/WelcomeColumn.jsx'))),
        'welcome-block': withSuspense(React.lazy(() => import('@/Components/Home/WelcomeBlock.jsx'))),
        'grid': withSuspense(React.lazy(() => import('@/Components/Grid.jsx'))),
        'links-grid': withSuspense(React.lazy(() => import('@/Components/LinksGrid.jsx'))),
        'intro': withSuspense(React.lazy(() => import('@/Components/Intro.jsx'))),
        'store-intro': withSuspense(React.lazy(() => import('@/Components/Store/Intro.jsx'))),
        'store-staff': withSuspense(React.lazy(() => import('@/Components/Store/Staff.jsx'))),
        'story': withSuspense(React.lazy(() => import('@/Components/Story.jsx'))),
        'story-grid': withSuspense(React.lazy(() => import('@/Components/StoryGrid.jsx'))),
        'featured-story': withSuspense(React.lazy(() => import('@/Components/FeaturedStory.jsx'))),
    },
    apiOptions: {
        region: "us"
    },
});

const createRoot = async () => {
    const {createRoot: reactCreateRoot} = await import('react-dom/client');
    return reactCreateRoot;
};

const appName = import.meta.env.VITE_APP_NAME || 'Cool Frames';

const initializeBugsnag = async () => {
    const Bugsnag = (await import('@bugsnag/js')).default;
    const BugsnagPluginReact = (await import('@bugsnag/plugin-react')).default;
    const BugsnagPerformance = (await import('@bugsnag/browser-performance')).default;

    Bugsnag.start({
        apiKey: '311cf6a0415b23c1182c1754a941ac1e',
        plugins: [new BugsnagPluginReact()]
    })
    BugsnagPerformance.start({apiKey: '311cf6a0415b23c1182c1754a941ac1e'})

    return Bugsnag.getPlugin('react').createErrorBoundary(React);
};

createInertiaApp({
    title: (title) => {
        if (!title) {
            return appName
        }
        return `${title} - ${appName}`
    },
    resolve: (name) => {
        return resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
    },
    setup: async ({el, App, props}) => {
        //el.classList.add('h-full')
        const zohoWidget = props.initialPage.props.zohoWidget;
        const reactCreateRoot = await createRoot();
        const root = reactCreateRoot(el);
        const currentPath = window.location.pathname;

        if (currentPath === '/') {
            const urgentModule = await import('./frontHomeUrgent.js');
            window.Front = new urgentModule.default();

            setTimeout(() => {
                import('./frontHomeLazy.js').then(lazyModule => {
                    Object.assign(urgentModule.default.prototype, lazyModule.default);
                    window.Front.addBindings();
                })
            }, 1000);
        } else import('./front.js');

        // const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

        const ErrorBoundary = await initializeBugsnag();

        root.render(
            <ErrorBoundary>
                <ChatProvider zohoWidget={zohoWidget}>
                    <App {...props} />
                </ChatProvider>
            </ErrorBoundary>
        );
    }
}).then(() => {
    document.getElementById('app').removeAttribute('data-page')
});

// Initialize Algolia Insights
aa('init', {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID,
    apiKey: import.meta.env.VITE_ALGOLIA_SEARCH_KEY,
    useCookie: true
});
