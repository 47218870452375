import React, { createContext, useState, useRef } from 'react';

// Create a Context for the Chat functionality
export const ChatContext = createContext({
    handleChatButtonClick: () => {},
    isChatLoaded: false,
    isChatLoading: false,
});

// ChatProvider component that will wrap application or parts of it
export const ChatProvider = ({ children, zohoWidget }) => {
    // State to track if the chat script has been fully loaded and initialized
    const [isChatLoaded, setIsChatLoaded] = useState(false);
    // State to track if the chat script is currently loading
    const [isChatLoading, setIsChatLoading] = useState(false);
    // useRef to store the promise of the script loading process to prevent multiple loads
    const scriptLoadingPromiseRef = useRef(null);
    // useRef to ensure the ready callback is set only once
    const readyCallbackRef = useRef(false);

    /**
     * Function to dynamically load the Zoho SalesIQ script
     * Returns a promise that resolves when the script is ready
     */
    const loadZohoSalesIQ = () => {
        // If the script is already loading or loaded, return the existing promise
        if (scriptLoadingPromiseRef.current) {
            return scriptLoadingPromiseRef.current;
        }

        // Create a new promise for loading the script
        scriptLoadingPromiseRef.current = new Promise((resolve, reject) => {
            // Set the loading state to true as the script loading starts
            setIsChatLoading(true);

            // Initialize the global $zoho.salesiq object if it doesn't exist
            window.$zoho = window.$zoho || {};
            window.$zoho.salesiq = window.$zoho.salesiq || {};
            // Set the widget code provided via props
            window.$zoho.salesiq.widgetcode = zohoWidget;
            // Initialize values object if it doesn't exist
            window.$zoho.salesiq.values = window.$zoho.salesiq.values || {};

            // If the ready callback hasn't been set yet, set it now
            if (!readyCallbackRef.current) {
                window.$zoho.salesiq.ready = function () {
                    // Update state to indicate the chat is loaded
                    setIsChatLoaded(true);
                    // Update state to indicate loading has finished
                    setIsChatLoading(false);
                    // Resolve the promise to indicate successful loading
                    resolve();
                };
                // Mark that the ready callback has been set to prevent duplicates
                readyCallbackRef.current = true;
            }

            // Check if the script is already present in the document
            if (!document.getElementById('zsiqscript')) {
                // Create a new script element
                const script = document.createElement('script');
                script.id = 'zsiqscript';
                // Ensure that the widget code is defined
                if (!zohoWidget) {
                    console.error('zohoWidget is undefined');
                    // Set loading state to false as loading cannot proceed without widget code
                    setIsChatLoading(false);
                    // Reject the promise with an error
                    reject(new Error('zohoWidget is undefined'));
                    return;
                }
                // Set the source of the script to Zoho SalesIQ with the widget code as a query parameter
                script.src = `https://salesiq.zohopublic.com/widget?widgetcode=${zohoWidget}`;
                script.defer = true; // Defer the script execution

                // Handle script error event
                script.onerror = () => {
                    console.error('Failed to load Zoho SalesIQ script');
                    // Set loading state to false as the script failed to load
                    setIsChatLoading(false);
                    // Reject the promise with an error
                    reject(new Error('Failed to load Zoho SalesIQ script'));
                };

                // Append the script to the document body to start loading
                document.body.appendChild(script);
            }
        });

        // Return the promise associated with the script loading
        return scriptLoadingPromiseRef.current;
    };

    /**
     * Function to handle the chat button click event
     * It loads the chat script if not loaded and then opens the chat window
     */
    const handleChatButtonClick = () => {
        // Check if the chat script has already been loaded
        if (isChatLoaded) {
            // Ensure that the floatwindow object exists before attempting to show it
            if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.floatwindow) {
                try {
                    // Use the Zoho SalesIQ API to make the chat window visible
                    window.$zoho.salesiq.floatwindow.visible('show');
                } catch (error) {
                    // Log any errors that occur while trying to show the chat window
                    console.error('Error showing chat window:', error);
                }
            } else {
                // Log an error if the floatwindow object is not found
                console.error('Float window object not found');
            }
        }
        // If the chat is not loaded and not currently loading, start the loading process
        else if (!isChatLoading) {
            // Call the function to load the Zoho SalesIQ script
            loadZohoSalesIQ()
                .then(() => {
                    // Initialize a counter for retry attempts
                    let attempts = 0;
                    const maxAttempts = 20; // Maximum number of attempts
                    const interval = 100; // Interval between attempts in milliseconds

                    /**
                     * Recursive function to check if the floatwindow object is ready
                     * and then show the chat window
                     */
                    const checkFloatWindow = () => {
                        // Check if the floatwindow object exists
                        if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.floatwindow) {
                            try {
                                // Attempt to show the chat window
                                window.$zoho.salesiq.floatwindow.visible('show');
                            } catch (error) {
                                // Log any errors that occur while trying to show the chat window
                                console.error('Error showing chat window:', error);
                            }
                        } else {
                            // Increment the attempt counter
                            attempts++;
                            // If the maximum number of attempts hasn't been reached, retry after the specified interval
                            if (attempts < maxAttempts) {
                                setTimeout(checkFloatWindow, interval);
                            } else {
                                // Log an error if the floatwindow is still not ready after maximum attempts
                                console.error('Float window not ready after maximum attempts');
                            }
                        }
                    };

                    // Start checking if the floatwindow is ready
                    checkFloatWindow();
                })
                .catch((error) => {
                    // Log any errors that occur during the script loading process
                    console.error('Error loading Zoho SalesIQ:', error);
                });
        }
    };

    return (
        // Provide the ChatContext to child components
        <ChatContext.Provider value={{ handleChatButtonClick, isChatLoaded, isChatLoading }}>
            {children}
        </ChatContext.Provider>
    );
};
