import React, {Suspense, useMemo} from 'react';
import Hero from '@/Components/Hero';
import {StoryblokComponent, storyblokEditable} from "@storyblok/react";
import {usePage} from "@inertiajs/react";

const ContentPage = ({ blok }) => {
    const className = blok.class || 'home-page';
    const { desktopImageUrl, mobileImageUrl } = usePage().props;

    const [heroBlok, bannerTop, otherBloks] = useMemo(() => {
        const hero = blok.content.find((b) => b.component === 'hero');
        const banner = blok.content.find((b) => b.component === 'banner-top');
        const others = blok.content.filter((b) => b.component !== 'hero' && b.component !== 'banner-top');
        return [hero, banner, others];
    }, [blok.content]);

    return (
        <main className={className} {...storyblokEditable(blok)} key={blok._uid}>
            <Suspense fallback={null}>
                {bannerTop && (
                    <StoryblokComponent
                        blok={bannerTop}
                        key={bannerTop._uid}
                    />
                )}
            </Suspense>
            {heroBlok && (
                <Hero
                    blok={heroBlok}
                    desktopImageUrl={desktopImageUrl}
                    mobileImageUrl={mobileImageUrl}
                    key={heroBlok._uid}
                />
            )}
            <Suspense fallback={null}>
                {otherBloks.map((blok) => (
                    <StoryblokComponent
                        blok={blok}
                        key={blok._uid}
                    />
                ))}
            </Suspense>
        </main>
    );
};

export default ContentPage;
